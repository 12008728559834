@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
html{
    overflow-x: hidden;
    overflow-y: auto !important;
}

::-webkit-scrollbar {
    width: 15px;
    border-radius: 100px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #212020bd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555555c4;
}